import React from "react";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
//Reused Assets
import { Logo, Menu, CotizaConNosotrosButton, Footer } from "./Home";
import "../src/static/fonts/Exo-Regular.ttf";
//New Assets
import image1 from "../src/static/images/nosotros-image1.jpg";
import image2 from "../src/static/images/FOTO RETOQUE.jpg";
import "../src/static/css/Nosotros_styles.css";
export default function Nosotros() {
    const isNosotrosPage = true;
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        setIsPageLoaded(true);
    }, []);
    return (
        <body className={`app ${isPageLoaded ? 'loaded' : ''}`}>
            {/* Bootstrap CSS*/}
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
            {/* Bootstrap JS*/}
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

            <Background />
            <Logo />
            <Menu isNosotrosPage={isNosotrosPage} />
            <Footer />
        </body>
    )
}

const Background = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550;
    return (
        <div
            style={{
                backgroundColor: "#FAF9F6",
                height: "95vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
                margin: 0,
                padding: 0,
            }}
        >
            <GrayDivider />
            
        </div>
    )
}

const GrayDivider = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550 && windowWidth > 400;
    const isSmallMobile = windowWidth < 400;
    return (
        <div style={{
            backgroundColor: "#e6e6e6",
            height: "84vh",
            width: "100%",
            position: "relative",
            top: isMobile || isSmallMobile ? "110px" : "120px",
            paddingBottom: "0px",
            margin: 0,
        }}>
            <BlackDivider />
            <div style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "flex-start",
                height: "100%",
                maxWidth: "100%",
                padding: " 0 20px",
                boxSizing: "border-box",
                overflow: "hidden",
            }}>
            <NosotrosImage isMobile={isMobile} isSmallMobile={isSmallMobile} />
            </div>
            <div style={{
                display: "flex",
                justifyContent: isMobile || isSmallMobile ? "center" : "flex-end",
                position: "absolute",
                bottom: "10%",
                right: "15%",
            }}>
            <CotizaConNosotrosButton 
            style={{
                paddingBottom: "0px",
            }}
            />
            </div>
        </div>
    )
}

const BlackDivider = () => {
    return (
        <div style={{
            backgroundColor: "#000000",
            height: "67vh",
            width: "100%",
            position: "absolute",
            top: "0px",
        }}>
            <NosotrosText />
        </div>
    )
}

const NosotrosImage = ({ isMobile, isSmallMobile }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const getImageSize = () => {
        if (imageLoaded) {
            if (windowWidth >= 2000) {
                return '1060px';
            } else if (windowWidth >= 1700 && windowWidth < 2000) {
                return '51%';
            } else if (windowWidth >= 750 && windowWidth < 960) {
                return '55%';
            } else {
                return isMobile || isSmallMobile ? '100%' : '50%';
            }
        } else {
            return isMobile || isSmallMobile ? '100%' : '50%';
        }
    };
    return (
        <div className={`container nosotros-image`}
        style={{
            position: "absolute",
            top: isMobile ? "110px" : isSmallMobile ? "200px" : "0px",
            right: isMobile || isSmallMobile ? "0%" : "0px",
            left: isMobile || isSmallMobile ? "0px" : "0",
            display: "flex",
            margin: 0,
            marginBottom: "0px",
            marginTop: "0px",
            padding: 0,
            width: "100%",
            maxWidth: getImageSize(),
            clipPath: windowWidth >= 1170 ? 'inset(0px 0px 0px 0px)' : isMobile ? "inset(00px 0px 0px 0px)" : "inset(0px 0px 0px 0px)",
            opacity : isMobile || isSmallMobile ? 0.1 : 1,
            borderRadius: isMobile || isSmallMobile ? "20px" : "0px",
        }}>
            {!imageLoaded && (
                <img src="https://res.cloudinary.com/dhg4c7opq/image/upload/v1686201244/FOTO_RETOQUE-min_e8dsam.jpg"
                className="img-fluid"
                alt="Nosotros-image-alt"
                style={{
                    width: "100%",
                    top: "0px",
                    objectFit: 'contain',
                    alignSelf: "center",
                    marginTop: "0px",
                    marginBottom: "0px",
                }} />
            )}
            <img src={image2} 
            className="img-fluid"
            alt="Nosotros-image" 
            defer
            style={{ 
                width: "100%",
                objectFit: 'contain',
                alignSelf: "center",
                marginTop: "0px",
                marginBottom: "0px", 
                }} onLoad={handleImageLoad} />
        </div>
    )
}

const NosotrosText = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            position: "absolute",
            right: isMobile ? "7vw" : "6vw",
            top: "-0.4vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "30px",
            paddingRight: "0px",
            // border: "1px solid white",
            height: "50vh",
            width: isMobile ? "49vh" : isHalfScreen ? "40vh" : "80vh",
        }}>
            <p style={{
                fontFamily: "Exo-Regular",
                fontStyle: "regular",
                color: "#FAF9F6",
                fontSize: isMobile ? "3.5vw" : isHalfScreen ? "1.6vw" : "1.2vw",
            }}>
                <span style={{ marginBottom: "1.5em", display: "block" }}>
                Somos una empresa de distribución de químicos industriales con más de 27 años de 
                experiencia en el rubro.  Buscamos brindar a todos nuestros clientes insumos 
                y productos químicos de calidad, basados en nuestro liderazgo en costos y 
                apoyados en todos nuestros canales de distribución.  
                Todo nuestro equipo se encuentra comprometido en satisfacer constantemente las necesidades de 
                nuestros clientes brindando un servicio eficiente y seguro.  
                </span>
                <span style={{ display: "block" }}>
                Buscamos desarrollarnos como empresa líder en el sur de Peru, mediante la consolidación de nuestras 
                operaciones, el reconocimiento de la calidad y seguridad de nuestros productos y el desarrollo de 
                nuevos mercados. 
                </span>
            </p>
        </div>
    )
}