import React from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Nosotros from "../src/Nosotros.js";
import Productos from "../src/Productos.js";
import Cotizacion from '../src/Cotizacion';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" Component={Home} />
          <Route path="/nosotros" Component={Nosotros} />
          <Route path="/productos" Component={Productos} />
          <Route path="/cotizacion" Component={Cotizacion} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
