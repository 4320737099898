import { useState, useEffect, createContext, useContext, Fragment, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import React from "react";
//Reused Assets
import { Logo, Menu, CotizaConNosotrosButton, Footer } from "./Home";
import CotizacionForm from "./Cotizacion";
//New Assets
import "../src/static/css/Productos_styles.css";
//Fonts
import "../src/static/fonts/Exo-Bold.ttf";
import "../src/static/fonts/Exo-Regular.ttf";
import "../src/static/fonts/Exo-Light.ttf";
//Mira, voy a probar con las imágenes que ya hay, luego las cambias
import imagenCategoria1 from "../src/static/images/Categoria1Mineria.jpg";
import imagenCategoria2 from "../src/static/images/Categoria2Pintura.jpg";
import imagenCategoria3 from "../src/static/images/Categoria3Limpieza1.jpg";
import imagenCategoria4 from "../src/static/images/Categoria4Textil.jpg";
import imagenCategoria5 from "../src/static/images/Categoria5Alimentos.jpg";
import imagenCategoria6 from "../src/static/images/Categoria6Agroindustrias.jpg";
import imagenCategoria7 from "../src/static/images/Categoria7Piscinas.jpg";
import imagenCategoria8 from "../src/static/images/Categoria8Ferreteria.jpg";
import imagenCategoria9 from "../src/static/images/Categoria9Laboratorio.jpg";
import imagenCategoria10 from "../src/static/images/ImgCategoria10Curtiembre.jpg";


export default function Productos() {
    const isProductosPage = true;
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        setIsPageLoaded(true);
    }, []);
    return (
        <body className="productos-page">
            {/* Bootstrap CSS*/}
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous" />
            {/* Bootstrap JS*/}
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM" crossorigin="anonymous"></script>

            <Logo />
            <Menu isProductosPage={isProductosPage} />
            <Background />
            <Footer />
        </body>
    )
}

const Background = () => {
    return (
        <div className="background-container"
            style={{
                backgroundColor: "#e6e6e6",
                height: "190vh",
                position: "relative",
                overflow: "hidden",
            }}>
            <BlackDivider />
            <Pasos />
            <NuestrosProductosTitle />
            <ProductosContainer />
            <div className="empty-after-productos"
            style={{
                height: "10vh",
                position: "relative"
            }}>

            </div>
        </div>
    )
}

const BlackDivider = () => {
    return (
        <div className="black-divider"
            style={{
                backgroundColor: "black",
                height: "40vh",
            }}>
            <BlackDividerText />
        </div>
    )
}

const BlackDividerText = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isDesktop = windowWidth >= 2000;
    const isLaptop = windowWidth >= 1700 && windowWidth < 2000;
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            margin: 0,
            padding: 0,
            display: "grid",
            placeItems: "start",
            textAlign: "left",
            position: "relative",
        }}>
            <div style={{

                top: isDesktop ? "170px" : isHalfScreen ? "150px" : "130px",
                left: isMobile ? "10px" : "140px",
                position: "absolute",
                margin: 0,
                padding: 0
            }}>
                <h3 style={{
                    margin: 0,
                    padding: 0,
                    fontFamily: 'Exo-Light',
                    fontWeight: "lighter",
                    color: "#FAF9F6",
                    fontSize: isMobile ? "1.5em" : isHalfScreen ? "2em" : "3em",
                    letterSpacing: "0.1em",
                }}>
                    ADAPTADOS A LAS <br /> NECESIDADES DE TU NEGOCIO
                </h3>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                position: "absolute",
                top: isDesktop ? "210px" : isMobile ? "240px" : isHalfScreen ? "100px" : "170px",
                right: isMobile ? "50px" : "100px",
                margin: 0,
                padding: 0
            }}>
                <p style={{
                    fontFamily: 'Exo-Light',
                    color: "#FAF9F6",
                    fontSize: isMobile ? "0.9em" : isHalfScreen ? "1em" : "1.5em",
                    letterSpacing: "0.1em",
                    textAlign: "left",
                }}>QUÍMICA <br /> INDUSTRIAL</p>
            </div>
        </div>
    )
}

const Pasos = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            display: "flex",
            alignItems: "flex-start",
            position: "relative",
            top: "50px",
            left: isMobile ? "15px" : isHalfScreen ? "70px" : "160px",
            margin: 0,
            paddingRight: isMobile ? "10px" : 0,
            overflowX: "hidden",
        }}>
            <div style={{ marginRight: isMobile ? "5px" : "20px" }}>
                <h2 style={{
                    fontFamily: 'Exo-Regular',
                    fontWeight: "bolder",
                    fontSize: isMobile ? "1.5em" : "2em",
                    color: "#f9880d",
                    margin: 0,
                    padding: 0
                }}>
                    <span style={{
                        fontWeight: "lighter",
                        fontSize: "0.7em"
                    }} >PASO </span>{" "}01 </h2>
                <p style={{
                    fontFamily: 'Exo-Regular',
                    fontSize: isMobile ? "1em" : "1.2em"
                }}>Selecciona el/los productos que necesitas</p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "40" : "80"} height={isMobile ? "28" : "56"} fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
            </svg>
            <div style={{ marginLeft: isMobile ? "5px" : "20px" }}>
                <h2 style={{
                    fontFamily: 'Exo-Regular',
                    fontWeight: "bolder",
                    fontSize: isMobile ? "1.5em" : "2em",
                    color: "#f9880d",
                    margin: 0,
                    padding: 0
                }}>
                    <span style={{
                        fontWeight: "lighter",
                        fontSize: "0.7em"
                    }}>PASO </span>{" "} 02</h2>
                <p style={{
                    fontFamily: 'Exo-Regular',
                    fontSize: isMobile ? "1em" : "1.2em"
                }}>Haz tu cotización</p>
            </div>
        </div>
    )
}

const NuestrosProductosTitle = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = windowWidth < 550;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: isMobile ? "70px" : "100px",
            left: isMobile ? "10px" : isHalfScreen ? "70px" : "150px",
        }}>
            <h2 style={{
                fontFamily: 'Exo-Bold',
                fontSize: isMobile ? "1.5em" : "3em",
                color: "#f9880d",
                margin: 0,
                padding: 0
            }}>NUESTROS PRODUCTOS</h2>
            <hr style={{
                height: "0.5px",
                width: isMobile ? "100px" : "800px",
                backgroundColor: "#000",
                marginLeft: isMobile ? "10px" : "80px",
                padding: 0,
            }} />
        </div>
    )
}

const SearchBar = ({ onSearch }) => {
    const handleInputChange = (event) => {
        const searchTerm = event.target.value;
        onSearch(searchTerm);
    };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const isMobile = windowWidth < 550;
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isMobile ? "center" : "flex-end",
            position: "relative",
            top: isMobile ? "-10px" : "-70px",
            right: isMobile ? "0" : "150px",
            margin: isMobile ? "10px 0" : 0,
        }}>
            <div style={{
                position: "relative",
                marginLeft: isMobile ? "0" : "8px",
            }}>
                <input type="text" placeholder="Buscar"
                    style={{
                        border: isMobile ? "1px solid black" : "2px solid black",
                        borderRadius: "20px",
                        padding: "8px 12px",
                        paddingRight: "40px",
                        marginRight: isMobile ? "0" : "8px",
                        outline: "none",
                        width: isMobile ? "100%" : "320px",
                    }}
                    onChange={handleInputChange} />
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"
                    style={{
                        position: "absolute",
                        left: isMobile ? "205px" : "285px",
                        top: isMobile ? "10px" : "10px",
                        color: "gray",
                        cursor: "pointer",
                    }}
                >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
            </div>
        </div>
    )
}

export const productosSeleccionadosContext = createContext();

export const ProductosContainer = () => {

    const [categorias, setCategorias] = useState([]);
    const [productos, setProductos] = useState([]);
    const [categoriasFiltradas, setCategoriasFiltradas] = useState([]);
    const [categoriasOrdenadasParaTd, setCategoriasOrdenadasParaTd] = useState([]);
    const [categoriasOrdenadasParaResto, setCategoriasOrdenadasParaResto] = useState([]);
    const [categoriasTd, setCategoriasTd] = useState([]);
    //Link de los productos
    useEffect(() => {
        const obtenerProductos = async () => {
            try {
                const response = await fetch("https://r3tf5lg8v6.execute-api.us-east-1.amazonaws.com/produccion");
                const data = await response.json();
                // console.log(data);
                const productosConSeleccionado = data.map((producto) => ({
                    ...producto,
                    seleccionado: false,
                }))
                setProductos(productosConSeleccionado);
            } catch (error) {
                console.log("Error al obtener los datos: ", error);
            }
        };
        obtenerProductos();
    }, []);

    //Aquí iría el link de las categorías
    useEffect(() => {
        const obtenerCategorias = async () => {
            try {
                const response = await fetch("https://r3tf5lg8v6.execute-api.us-east-1.amazonaws.com/produccion/categories");
                const data = await response.json();
                 // Ordena las categorías por ID de manera ascendente
                const categoriasOrdenadas = data.sort((a, b) => a.id - b.id);
                
                // Mantiene el índice de las imágenes
                const categoriasConImagenesOrdenadas = categoriasConImagenes.slice(0);
                for (let i = 0; i < categoriasOrdenadas.length; i++) {
                    categoriasConImagenesOrdenadas[i] = categoriasConImagenes[categoriasOrdenadas[i].id];
                }

                // Ordena las categorías para el `<td>`
                const categoriasOrdenadasParaTd = categoriasOrdenadas.map((categoria) => categoria.nombre);
                console.log(categoriasOrdenadasParaTd);
                
                // Ordena las categorías para el resto de la página
                const categoriasOrdenadasParaResto = categoriasOrdenadas.map((categoria) => categoria.id);
                
                setCategorias(categoriasOrdenadasParaTd);
                setCategoriasFiltradas(categoriasOrdenadasParaResto);
                setCategoriasTd(categoriasOrdenadasParaTd);
                
            } catch (error) {
                console.log("Error al obtener los datos: ", error);
            }
        };
        obtenerCategorias();
    }, [searchTerm]);

    //Y aquí unimos ambos
    const categoriasConProductos = {};
    productos.forEach(producto => {
        if (!categoriasConProductos[producto.categoria]) {
            categoriasConProductos[producto.categoria] = [];
        }
        categoriasConProductos[producto.categoria].push(producto);
    });

    //Asociamos categorías con imágenes
    const categoriasConImagenes = [
        imagenCategoria1,
        imagenCategoria2,
        imagenCategoria3,
        imagenCategoria4,
        imagenCategoria5,
        imagenCategoria6,
        imagenCategoria7,
        imagenCategoria8,
        imagenCategoria9,
        imagenCategoria10
    ].sort();
    // console.log(categoriasConImagenes);

    const [searchTerm, setSearchTerm] = useState("");
    const [productosSeleccionados, setProductosSeleccionados] = useState([]);

    const handleProductoSeleccionado = (productoId) => {
        setProductos((prevProductos) => prevProductos.map((producto) => {
            if (producto.id === productoId) {
                return { ...producto, seleccionado: !producto.seleccionado };
            }
            return producto;
        })
        );
    };
    useEffect(() => {
        const productosFiltrados = productos.filter((producto) => producto.seleccionado);
        setProductosSeleccionados(productosFiltrados);
    }, [productos]);

    const productosFiltradosNombre = productos.filter((producto) => {
        return producto.nombre.toLowerCase().includes(searchTerm.toLowerCase());
    });
    // console.log(productosFiltradosNombre);
    const [contador, setContador] = useState("00");

    useEffect(() => {
        const productosFiltrados = productos.filter((producto) => producto.seleccionado);
        setProductosSeleccionados(productosFiltrados);
        setContador(String(productosFiltrados.length).padStart(2, "0"));
    }, [productos]);
    const handleSearch = (term) => {
        setSearchTerm(term);
        
    };

    

    //for the cotizacion
    useEffect(() => {
        localStorage.setItem("selectedProducts", JSON.stringify(productosSeleccionados));
    }, [productosSeleccionados]);
    // console.log(productosSeleccionados);

    useEffect(() => {
        const savedProducts = JSON.parse(localStorage.getItem("selectedProducts"));
        if (savedProducts) {
            setProductos((prevProductos) =>
                prevProductos.map((producto) => {
                    return savedProducts.find((selectedProduct) => selectedProduct.id === producto.id)
                        ? { ...producto, seleccionado: true }
                        : producto;
                })
            );
        }
    }, []);

    //mobile size
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const listaProductosRef = useRef();

    const isMobile = windowWidth < 550;

    return (
        <div style={{
            marginTop: isMobile ? "100px" : "200px",
            marginLeft: isMobile ? "50px" : "120px",
            marginRight: isMobile ? "50px" : "100px",
        }}>
            <productosSeleccionadosContext.Provider value={productosSeleccionados}>
            <SearchBar onSearch={handleSearch} />
            <div style={{
                maxHeight: "600px",
                overflowY: "scroll",
                scrollbarWidth: "thin",
                scrollbarColor: "#858585 #ffffff",
            }}>
                <style>
                    {`
                    /* Firefox */
                    .productos-container {
                        scrollbar-width: auto;
                        scrollbar-color: #858585 #ffffff;
                    }
                    /* Chrome, Edge, and Safari */
                    .productos-container::-webkit-scrollbar {
                        width: 16px;
                    }
                    .productos-container::-webkit-scrollbar-track {
                        background: #ffffff;
                    }
                    .productos-container::-webkit-scrollbar-thumb {
                        background-color: #858585;
                        border-radius: 10px;
                        border: 3px solid #ffffff;
                    }
                    `}
                </style>
                
                <table style={{ 
                    width: "100%",
                    marginBottom: isMobile? "80px" : "0px"}}>
                        <tbody>
                        <div style={{ height: "1px" }} ref={listaProductosRef}></div>
                {Object.keys(categoriasConProductos).map((categoria, index) => (
                    
                    <Fragment key={categoria}>
                    
                    <tr>
                        <td style={{
                            paddingRight: isMobile ? "10px" : "38px",
                            paddingLeft: isMobile ? "10px" : "38px",
                            width: isMobile? "15%" : "30%",
                            color: "#f9880d",
                            backgroundColor: "black",
                            border: "3px solid #f9880d",
                            fontFamily: 'Exo-Bold',
                            fontSize: isMobile ? "0.9em" : "1.7em",
                        }}>{categoriasTd[index]}</td>
                        <td style={{ 
                            padding: "0px", 
                            border: "3px solid #f9880d", 
                            borderRadius: "10px",
                            height: isMobile? "76px" : "200px" }}>
                        <img src={categoriasConImagenes[index]} alt={categoria} 
                        style={{
                            width: "100%", 
                            height: "auto",
                            objectFit: "cover",
                            maxWidth: isMobile? "100%" : "100%",
                            maxHeight: isMobile? "100%" : "100%" 
                        }}/>
                        </td>
                        </tr>
                    
                <tr>
                    <td colSpan="2">
                        <div className="productos-container" 
                        style={{ 
                            display: "grid", 
                            gridTemplateColumns: isMobile ? "1fr" : "repeat(3, 1fr)", 
                            gridGap: "20px",
                            paddingTop: "25px" }}>
                        {categoriasConProductos[categoria]
                            .filter(producto =>
                                producto.nombre.toLowerCase().includes(searchTerm.toLowerCase())
                            )
                            .map(producto => (
                                <div key={producto.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            
                
                        <input type="checkbox" 
                        checked={producto.seleccionado || false}
                        onChange={() => handleProductoSeleccionado(producto.id)}
                        style={{
                            margin: "0px 8px 10px 0px",
                            width: "20px",
                            height: "20px",
                        }}/>
                        <label style={{
                            fontFamily: 'Exo-Bold',
                            fontSize: isMobile? "0.9em" : "1.2em",
                            color: "#000000",
                        }}>{producto.nombre}</label>
                    </div>
                    ))}
                    <button onClick={() => {
                        if (listaProductosRef.current) {
                            listaProductosRef.current.scrollIntoView({  
                            block: "center" });
                            }
                        }}
                        className="scroll-to-top-button"
                        style={{
                            position: "fixed",
                            bottom: isMobile? "150px" : "120px",
                            right: "20px", 
                            border: "none",
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#f9880d" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                            </svg>
                        </button>
                </div>
                    
                </td>
                </tr>
                </Fragment>
            ))}
            </tbody>
            </table>
            </div>
            
            
            <SelectedProductos count={contador} />
            { false && <CotizacionForm productosSeleccionados={productosSeleccionados} /> }
            </productosSeleccionadosContext.Provider >
        </div >
    )
}

const SelectedProductos = ({ count }) => {
    const productosSeleccionados = useContext(productosSeleccionadosContext);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleProductoSeleccionado = (productoId) => {
        const updatedProductos = productosSeleccionados.map((producto) => {
            if (producto.id === productoId) {
                return { ...producto, seleccionado: !producto.seleccionado }
            }
            return producto;
        });
    };

    const isDesktop = windowWidth >= 2000;
    const isLaptop = windowWidth >= 1700 && windowWidth < 2000;
    const isMobile = windowWidth < 550 && windowWidth >= 400;
    const isSmallMobile = windowWidth < 400;
    const isHalfScreen = windowWidth >= 750 && windowWidth < 960;

    const navigate = useNavigate();
    const goToCotizacion = () => {
        navigate("/cotizacion");
    }

    return (
        <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "#f9880d",
            padding: "30px",
            color: "white",
            display: "flex",
            justifyContent: isMobile || isSmallMobile ? "center" : "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            fontFamily: "Exo-Light",
        }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                flexBasis: isMobile || isSmallMobile ? "100%" : "auto",
                flexGrow: 1,
            }}>
                <span style={{
                    marginLeft: isMobile || isSmallMobile ? "20px" : isHalfScreen ? "50px" : "110px",
                    marginRight: isDesktop ? "500px" : isLaptop ? "200px" : "10px",
                    fontFamily: "Exo-Regular",
                    fontSize: isMobile ? "1em" : isSmallMobile ? "0.8em" : "1.6em",
                    letterSpacing: isMobile || isSmallMobile ? "1px" : "1.5px",
                    whiteSpace: "nowrap",
                }}>PRODUCTOS SELECCIONADOS </span>
                <div style={{
                    width: isMobile || isSmallMobile ? "35px" : "45px",
                    height: isMobile || isSmallMobile ? "35px" : "45px",
                    border: "2px solid white",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Exo-Regular",
                    fontSize: isMobile ? "1em" : isSmallMobile ? "0.8em" : "1.2em",
                    fontWeight: "bold",
                }}>
                    {count}
                </div>
                <span style={{
                    marginRight: isDesktop ? "20px" : "20px",
                    marginLeft: isMobile || isSmallMobile ? "10px" : "20px",
                    fontFamily: "Exo-Regular",
                    fontSize: isSmallMobile ? "0.8em" : "1.1em",
                    letterSpacing: "1px",
                    whiteSpace: "nowrap",
                }}>PRODUCTOS</span>
            </div>

            <div style={{
                marginRight: isDesktop ? "400px" : "0px",
            }}>
                <CotizaConNosotrosButton productosSeleccionados={productosSeleccionados} />
                {productosSeleccionados && productosSeleccionados.map((producto) => (
                    <div key={producto.id}
                        onClick={() => handleProductoSeleccionado(producto.id)}>
                    </div>))}
            </div>
        </div>
    )
}
